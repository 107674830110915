;(function ($) {
    var sliderOnze = $(".uk-section-onze-aanpak .uk-section-media");
    sliderOnze.slick({
        dots: true,
        customPaging: function(slider, i) {
            var count = i+1;
            return '<a class="uk-count"><span>' + count + '</span><div class="slide-subtitle">'+$(slider.$slides[i]).find('.uk-item').attr('title')+'</div></a>';
        },
        infinite: false,
        arrows: true,
        slidesToShow: 1
    });

    sliderOnze.on("afterChange", function (event, slick, currentSlide) {
        sliderOnze.find('.slick-dots li').removeClass('uk-no-active');
        addClassDots();

        var docWidth = jQuery(window).width()-70;
        var posActivDot = sliderOnze.find('.slick-dots li.slick-active').position().left;
        //if(posActivDot > docWidth) {
            sliderOnze.find('.slick-dots').scrollLeft(posActivDot);
        //}

    });

    function addClassDots() {
        sliderOnze.find('.slick-dots li.slick-active').nextAll().addClass('uk-no-active');
    }
    addClassDots();
})(jQuery);