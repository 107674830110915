(function ($) {
  $('.newsletter_sign_up .close-btn').on('click', function () {
    $(this).closest('.newsletter_sign_up').fadeOut(200);
    $('.newsletter_sign_up_overlay').removeClass('uk-active-overlay');
    $('html').removeClass('uk-modal-page');

    reset();
  });

  let timecap = $('.newsletter_sign_up').data('timecap');
  var interval = timecap*60000;

  function reset()
  {
    if(timecap) {
      sessionStorage.endTime = +new Date + interval;
    }
    sessionStorage.setItem('show_newsletter_popup', 'hide');
  }

  if(sessionStorage.endTime) {
    var remaining = sessionStorage.endTime - new Date;
    if( remaining <= 0 ) {
      sessionStorage.removeItem("show_newsletter_popup");
      sessionStorage.removeItem("endTime");
    }
  }


  // show block after ... s
  $(document).ready(function () {

    let showafter = $('.newsletter_sign_up').data('showafter');
    let showafterpx = $('.newsletter_sign_up').data('showafterpx');
    let mql = window.matchMedia('all and (max-width: 640px)');

    sessionStorage.setItem('newsletter_popup', 'false');

    if (sessionStorage.getItem('show_newsletter_popup') !== 'hide') {
      if(typeof showafter !== 'undefined') {
        setTimeout(function(){
          $('.newsletter_sign_up').fadeIn(200);
          $('.newsletter_sign_up_overlay').addClass('uk-active-overlay');
          $('html').addClass('uk-modal-page');
        }, showafter*1000);
      }

      if(typeof showafterpx !== 'undefined') {
        $(window).on('scroll', function() {
          if ($(window).scrollTop() >= showafterpx && sessionStorage.getItem('newsletter_popup') === 'false') {
            $('.newsletter_sign_up').fadeIn(200);
            sessionStorage.setItem('newsletter_popup', 'true');
            $('.newsletter_sign_up_overlay').addClass('uk-active-overlay');
            $('html').addClass('uk-modal-page');
          }
        });
      }
    }

    jQuery(document).on('gform_confirmation_loaded', function(event, formId){
      if(jQuery('#gform_confirmation_wrapper_'+formId).parents('.newsletter_sign_up').length) {
        sessionStorage.setItem('show_newsletter_popup', 'hide');
      }
    });

  });
})(jQuery);
