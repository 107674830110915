;(function ($) {

    $('.uk-section-media-overview .uk-label-list label').on('click', function () {
       var url = window.location.href;
        if (/page/.test(url)) {
            var res = url.split("/page/");
            window.history.pushState('object', document.title, res[0]);
        }
    });

    $('.uk-button-more').on('click', function(e){
        e.preventDefault();
        $(this).hide().nextAll().css('display', 'block');
    });

})(jQuery);