;(function ($) {

    var sliderContainerHero = $('.uk-slick-slider'),
    currentIndex = $('.uk-section-header_csc .uk-slider-posts-nav-current');

    sliderContainerHero.slick({
        infinite: false,
        centerMode: true,
        slidesToShow: 1,
        speed: 500,
        swipe: true,
        touchMove: true,
        appendArrows: $('.uk-section-header_csc .uk-slider-posts-nav'),
        prevArrow: "<button type=\"button\" class=\"slick-arrow slick-prev\"></button>",
        nextArrow: "<button type=\"button\" class=\"slick-arrow slick-next\"></button>",
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    swipe: false,
                    touchMove: false
                }
            }
        ]
    });

    sliderContainerHero.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        // $(this).find('.slick-slide').removeClass('customClass');
        $(this).find('.slick-slide[data-slick-index="'+currentSlide+'"]').addClass('customClass');
    });

    sliderContainerHero.on('afterChange', function(event, slick, currentSlide, nextSlide){
        $(this).find('.slick-slide.slick-current').prevAll().addClass('customClass');
        $(this).find('.slick-slide.slick-current').nextAll().removeClass('customClass');
        $(this).find('.slick-slide.slick-current').removeClass('customClass');
    });

    var deviceAgent = navigator.userAgent.toLowerCase();

    var agentID = deviceAgent.match(/(android|iphone|ipod|ipad)/);

    if (!agentID) {


        // if (sliderContainerHero.length > 0) {
        //         autoPlayVideo('.uk-media-frame');
        // }
    }

    $(sliderContainerHero).find('video').each(function (index,item) {
        $(item).removeAttr('controls');
        // $(item).siblings('.btn-play').addClass('hide');
    });

    sliderContainerHero.on('reInit afterChange', function(event, slick, currentSlide, nextSlide){
        var element = $(this).find('.slick-slide[data-slick-index="'+currentSlide+'"]').find('.uk-media-frame');
        // autoPlayVideo(element);

        if (currentSlide < 9) {
            currentIndex.text('0' + (currentSlide + 1));
        } else {
            currentIndex.text(currentSlide + 1);
        }
    });

    sliderContainerHero.on('reInit beforeChange', function(event, slick, currentSlide, nextSlide){
        var element = $(this).find('.slick-slide[data-slick-index="'+currentSlide+'"]').find('.uk-media-frame');
        autoPauseVideo(element);
    });

    function autoPlayVideo(el) {
        if($(el).children('video').length) {
            $(el).children('video').get(0).play();
            $(el).children('.btn-play').fadeOut();
            $(el).children('.uk-play-fullscreen').fadeIn();
        }
    }
    function autoPauseVideo(el) {
        if($(el).children('video').length) {
            $(el).children('video').get(0).pause();
            $(el).children('.btn-play').fadeIn();
            $(el).children('.uk-play-fullscreen').fadeOut();
        }
    }

    //Custom controls

    $('.uk-media-frame').click(function (e) {
        e.preventDefault();
        var idMedia = $(this).find('iframe');

        if (!idMedia.length) {
            if ($(this).children('video').get(0).paused) {
                autoPlayVideo(this);
            } else {
                autoPauseVideo(this);
            }
        }

    });

    jQuery('.uk-play-fullscreen').on('click', function () {
        var elem = jQuery(this).parent().find('iframe, video').get(0);
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    });


    function setupTypewriter(t) {
        var HTML = t.innerHTML;

        t.innerHTML = "";

        var cursorPosition = 0,
            tag = "",
            writingTag = false,
            tagOpen = false,
            typeSpeed = 100,
            tempTypeSpeed = 0;

        var type = function() {

            if (writingTag === true) {
                tag += HTML[cursorPosition];
            }

            if (HTML[cursorPosition] === "<") {
                tempTypeSpeed = 0;
                if (tagOpen) {
                    tagOpen = false;
                    writingTag = true;
                } else {
                    tag = "";
                    tagOpen = true;
                    writingTag = true;
                    tag += HTML[cursorPosition];
                }
            }
            if (!writingTag && tagOpen) {
                tag.innerHTML += HTML[cursorPosition];
            }
            if (!writingTag && !tagOpen) {
                if (HTML[cursorPosition] === " ") {
                    tempTypeSpeed = 0;
                }
                else {
                    tempTypeSpeed = (Math.random() * typeSpeed) + 50;
                }
                t.innerHTML += HTML[cursorPosition];
            }
            if (writingTag === true && HTML[cursorPosition] === ">") {
                tempTypeSpeed = (Math.random() * typeSpeed) + 50;
                writingTag = false;
                if (tagOpen) {
                    var newSpan = document.createElement("span");
                    t.appendChild(newSpan);
                    newSpan.innerHTML = tag;
                    tag = newSpan.firstChild;
                }
            }

            cursorPosition += 1;
            if (cursorPosition < HTML.length - 1) {
                setTimeout(type, tempTypeSpeed);
            }

        };

        return {
            type: type
        };
    }

    var typer = document.getElementById('typewriter');
    if (document.body.contains(typer)) {
        typewriter = setupTypewriter(typewriter);

        typewriter.type();
    }

})(jQuery);