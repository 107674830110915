;(function ($) {

  $(document).ready(function() {
    setTimeout(function(){
      $('.uk-hero-testdrive').addClass('uk-hero-visible');
    }, 500);
  });

    // function badgeGreenModule(){
    //     if(jQuery('.uk-section-badge').length) {
    //       var badgeTop = jQuery('.uk-section-badge').offset().top;
    //
    //         if((jQuery(window).scrollTop() > badgeTop -150) && (jQuery(window).scrollTop() < badgeTop)) {
    //             // if (0 + jQuery(window).scrollTop() / badgeTop-150 > 0) {
    //                 $(".uk-section-badge").css("opacity", 0 + jQuery(window).scrollTop() / badgeTop);
    //             // }
    //         }
    //     }
    // }
    // badgeGreenModule();
    // $(window).scroll(function(){
    //     badgeGreenModule();
    // });

})(jQuery);