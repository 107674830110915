(function ($) {
  $('.uk-video-thumb .uk-button-youtube a').on('click', function(e){
    e.preventDefault();
    let thumb = $(this).parents('.uk-has-iframe');
    thumb.find('.uk-video-thumb').fadeOut();
    let iframe = thumb.find('iframe');
    let videoURL = iframe.prop('src');
    videoURL += "&autoplay=1";
    iframe.prop('src',videoURL);
  });
})(jQuery);
