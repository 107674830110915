// jshint ignore: start

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  var isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/));

  $('[type="file"]').after('<span class="uk-input-file"></span>');
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Add class upload label
    function addClassLabel() {
      $('.ginput_container_fileupload').each(function(){
          $(this).parent().find('.gfield_label').addClass('uk-upload-label');
      });
    }
    addClassLabel();

    function hotiarAttr() {
        $('.gfield input, .gfield textarea').each(function(){
            $(this).attr('data-hj-suppress', '');
        });
    }
    hotiarAttr();

  // On / off sound video
  $(".uk-has-video video").prop('muted', true);

  $(".uk-button-sound").click( function (e){
    e.preventDefault();
    if( $(".uk-has-video video").prop('muted') ) {
      $(".uk-has-video video").prop('muted', false);
      $(this).addClass('uk-active');
    } else {
      $(".uk-has-video video").prop('muted', true);
      $(this).removeClass('uk-active');
    }
  });

  var getFilter = $('.uk-section-filters');
  var getClassFilter = $('.get_post_type_id').text();

  if(getClassFilter.length) {
      getFilter.addClass(getClassFilter);
  }else {
      getFilter.removeClass('uk-hidden');
  }

  $('[data-filter], [data-value]').on('submit', function (e) {
    e.preventDefault();
  });


  var $left = $(".left");
  var $gl = $(".gallery");
  var $gl2 = $(".gallery2");
  var $photosCounterFirstSpan = $(".photos-counter span:nth-child(1)");

  $gl2.on("init", function (event, slick) {
    $photosCounterFirstSpan.text(slick.currentSlide + 1 + "/");
    $(".photos-counter span:nth-child(2)").text(slick.slideCount);
    if(slick.slideCount > 4) {
      $('<span class="uk-slide-overlay"></span>').insertAfter('.gallery .arrow-right')
    }
  });

  $gl.slick({
    rows: 0,
    slidesToShow: 4,
    infinite: true,
      prevArrow:"<button type='button' class='slick-prev arrow-left'></button>",
      nextArrow:"<button type='button' class='slick-next arrow-right'></button>",
    draggable: false,
    useTransform: false,
    mobileFirst: true
     });


  $gl2.slick({
    rows: 0,
    useTransform: false,
    prevArrow: ".arrow-left",
    nextArrow: ".arrow-right",
    fade: true,
    asNavFor: $gl });


  function handleCarouselsHeight() {
    if (window.matchMedia("(min-width: 1024px)").matches) {
      var gl2H = $(".gallery2").height();
      $left.css("height", gl2H);
    } else {
      $left.css("height", "auto");
    }
  }

  $(window).on("load", function () {
    handleCarouselsHeight();
    setTimeout(function () {
      $(".loading").fadeOut();
      $("body").addClass("over-visible");
    }, 300);
  });

  $(".gallery .item").on("click", function () {
    var index = $(this).attr("data-slick-index");
    $gl2.slick("slickGoTo", index);
  });

  $gl2.on("afterChange", function (event, slick, currentSlide) {
    $photosCounterFirstSpan.text(slick.currentSlide + 1 + "/");
  });

  function stickyUpdate() {

    setTimeout(function () {
      $('[uk-sticky]').each(function () {
        var $this = $(this);
        var height = $this.outerHeight();
        $this.next('.uk-sticky-placeholder').css('height', height);
      });
    }, 250);

    $('.uk-rail-inner').each(function () {
      var $this = $(this);
      var width = $this.outerWidth();
      $this.closest('.uk-rail').css('min-width', width);
      if ($this.hasClass('uk-sticky')) {
        $this.closest('.uk-rail').addClass('uk-rail-sticky');
      }
    });
  }

  function slickInit() {
      $('[data-slick]').each(function(){
          var slickSlider = $(this);
          slickSlider.on('init', function () {
              var $this = $(this);
              $this.children().wrapAll('<div class="slick-body"></div>');
          });

          slickSlider.on('breakpoint', function () {
              var $this = $(this);
              $this.find('.slick-body').remove();
          });

          slickSlider.on('destroy', function () {
              var $this = $(this);
              setTimeout(function () {
                  $this.find('.slick-body').children().unwrap();
              }, 250);
          });

          slickSlider.each(function () {
              var $this = $(this);
              var data = {};
              $.extend(true, data, $this.data('slick'));
              // Object.assign(data, $this.data('slick'));
              $this.slick(data);
          });
      })

  }

  function selectInit() {
    var $select = $('select');



    $select.each(function () {
      var $this = $(this);
      var data = {
        copyClassesToDropdown: false,
        inputClass: 'uk-select selectize-input',
        onInitialize: function () {
          $this.next('.selectize-control').removeClass('uk-select');
        }
      };
      $.extend(true, data, $this.data('select'));
      // Object.assign(data, $this.data('select'));
      localStorage.setItem('select_options', JSON.stringify(data));
      $this.selectize(data);
    });

  }

  function countInit() {
    var o = jQuery('.count');
    var cc = 1;

    if (o.length > 0) {

      jQuery(window).scroll(function () {
        var elemPos = o.offset().top;
        var elemPosBottom = o.offset().top + o.height();
        var winHeight = jQuery(window).height();
        var scrollToElem = elemPos - winHeight;
        var winScrollTop = jQuery(this).scrollTop();

        if (winScrollTop > scrollToElem) {
          if (elemPosBottom > winScrollTop) {
            if (cc < 2) {
              cc = cc + 2;
              jQuery(document).ready(function () {
                o.countTo();
              });
            }
          }
        }
      });
    }
  }

  function elementScroll() {
    $('[data-element-scroll]').each(function (i) {
      var $this = $(this);
      var data = $this.data('element-scroll');
      var clone = data.clone;
      var cloneItem = $this.clone();
      // console.log(cloneItem);
      if (clone) {
        $this.append('<div class="uk-element-clone"><div class="' + $this[0].className + '">' + $this[0].innerHTML + '</div></div>');
      }
    });
  }

  slickInit();
  stickyUpdate();
  elementScroll();

  $(document).bind('gform_post_render', function () {
    hotiarAttr();
    selectInit();
    addClassLabel();
  });

  $(window).on('resize', function () {
    UIkit.update(event = 'update');
  });

  $(document).on('touchstart', function(e) {
    setTimeout(function() {
      document.activeElement.blur();
    }, 0);
  });


  $('.gfield_checkbox input[type="checkbox"]').each(function(){
    var checkBoxVal = jQuery(this).val();
    $(this).next('label').html(checkBoxVal);
  });

  function badgeWhite(){
      if ($(window).scrollTop() / 250 <= 1){
          $(".uk-badge-header").css("opacity", 0 + $(window).scrollTop() / 250);
      } else {
          $(".uk-badge-header").css("opacity", 1);
      }
  }
  // badgeWhite();

  // $(window).scroll(function(){
  //     badgeWhite();
  // });

// hide badge
  $(window).scroll(function() {
      if ($(this).scrollTop() > 250) {
          $('.uk-section-badge').hide(1000);
      }else {
          $('.uk-section-badge').show(1000);
      }
  });

  $('.uk-section-article iframe').each(function(){
    $(this).wrap('<div class="uk-has-iframe"></div>');
  });

  $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
          $(".uk-class-testdrive-btn").fadeOut();
      } else {
          $(".uk-class-testdrive-btn").fadeIn();
      }
  });

  var searchModalRequest = {
    props: {
      modalTarget: '.uk-modal',
      modalsearch: 'uk-modal-search',
    },
    init: function () {
      var self = this;
      this.targetActivatesearchInput(this.props.modalTarget);
      $('.module-menu-search').on('keyup', function () {
        var $this = $(this);
        delay(function () {
          self.ajaxRequest($this);
        }, 500);
      });
    },
    targetActivatesearchInput: function (item) {
      $(item).on({
        'show.uk.modal': function (e) {
          var modalTarget = $(e.target).find('input');
          setTimeout(function () {
            modalTarget.eq(0).focus();
          });
        },
      });
    },
    ajaxRequest: function (element) {
      var data = {
        'action': 'module_menu_search',
        's': element.val(),
        'query': element.data('query'),
      };
      $.ajax({
        url: window.ajaxurl,
        type: "POST",
        data: data,
        success: function (response) {
          response = JSON.parse(response);
          var $el = $("body");
          if (response.count){
            $el.find('.uk-search-menu-list').html(response.view);
          } else{
            $el.find('.uk-search-menu-list').html('<div class="uk-empty-results">'+response.empty_results+'</div>');
          }

          $el.find('.uk-search-count').html(response.count).show();
          if (response.count_text) {
            $el.find('.uk-search-label').html(response.count_text).show();
          }
          // self.showLoadingMore();

        },
      });
    },
  };

  searchModalRequest.init();

  function navigationSlide() {
    $('.uk-offcanvas-nav').find('li > .menu-item-has-children').on('click', function(e){
      e.preventDefault();
      $('body').addClass('uk-open-navsub');
      $(this).parent().addClass('uk-active-subitem');
    });

    $('.uk-nav-sub-back').on('click', function(e){
      e.preventDefault();
      $('body').removeClass('uk-open-navsub');
      var activeItem = $('.uk-offcanvas-nav').find('.uk-active-subitem');
      if(activeItem.length) {
        activeItem.removeClass('uk-active-subitem uk-open');
      }
    });

    $('.uk-offcanvas-nav > li > a').mouseover(function(){
      $('.uk-offcanvas-nav > li').removeClass('uk-open');
      $('.uk-offcanvas-nav > li.uk-parent > ul').attr('hidden', 'hidden');
      $(this).parent().addClass('uk-open');
      $(this).parent().find('>.uk-nav-sub').removeAttr('hidden');
    });

    $('.uk-offcanvas-nav > li > a.menu-item').on('click', function () {
      var getHref = $(this).attr('href');
      if($(this).hasClass('uk-nolink') && !isMobile && (window.innerWidth > 960)) {
        return false;
      }else {
        if(!isMobile && window.innerWidth > 960) {
          window.location = getHref;
          return false;
        }
      }
      if($(this).hasClass('uk-nolink-mobile') && isMobile && window.innerWidth < 960) {
        $(this).parents('.uk-offcanvas-nav').css('transform', 'translate(0)');
        $(this).next().css('visibility', 'hidden');
        window.location = getHref;
        return false;
      }
    })
  }
  navigationSlide();

  $(window).on('load', function(){
    if($('.uk-has-paged').length) {
      $([document.documentElement, document.body]).animate({
        scrollTop: $('.uk-section-body .uk-first-card-ajax').offset().top-90
      }, 500);
    }
  });

  $(".uk-sticky-side-button .uk-scroll-to").click(function() {
    var href = $(this).attr('href');
    var id = href.substring(1);
    $([document.documentElement, document.body]).animate({
      scrollTop: $("."+id+"").offset().top-100
    }, 500);
  });

  UIkit.util.on('#modal-contact-author', 'show', function () {
    window.location.hash = $(this).attr("id");
  });

  if(window.location.hash && $(window.location.hash).length) {
    let sections = document.getElementsByClassName("uk-section");
    let hash = window.location.hash;
    for (let i = 0; i < sections.length; i++) {
      let id = '#'+sections[i].id;
      if(id === hash) {
        return false;
      }
    }
    UIkit.modal(window.location.hash).show();
  }

  jQuery(document).on('gform_confirmation_loaded', function(event, formId){
    var getClass = $('#gform_confirmation_wrapper_'+formId).attr('class');
    if(getClass.indexOf("linkedin") >= 0) {
      var getID = getClass.split("linkedin_");
      window.lintrk('track', { conversion_id: getID[1] });
    }
  });


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        selectInit();
        countInit();

        // objectFitImages(false, {
        //   watchMQ: true,
        //   skipTest: true
        // });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
        // var video = document.getElementById('video_header');
        // video.play();
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('.ginput_recaptcha').each(function(i)
  {
    var thisID = $(this).attr('id');
    $(this).attr('id', thisID+'_'+i);
  });

  // Magnify
  $('img[data-magnify-src]').magnify();

  // Cookiebot settings
  jQuery(window).on('CookiebotOnDialogInit', function (){
    addMsgCookiebot();
  });

  jQuery(window).on('CookiebotOnConsentReady', function (){
    var cookiebot = window.Cookiebot;
    if (!cookiebot.consent.marketing) {
      addMsgCookiebot();
    }
  });

  function addMsgCookiebot(){
    var getContainerVideo = $('.uk-has-iframe');
    getContainerVideo.each(function (){
      $(this).find('span').remove();
      $(this).addClass('consent-marketing').append('<span>De marketing cookies moeten worden geaccepteerd om deze content te bekijken. <a href="javascript: Cookiebot.renew()">Klik hier</a> om cookies aan te passen.</span>');
    });
    $('.uk-button-youtube[uk-lightbox]').parent().hide();
  }

  window.addEventListener('CookiebotOnAccept', function () {
    var cookiebot = window.Cookiebot;
    if (cookiebot.consent.marketing) {
      var getContainerVideo = $('.uk-has-iframe');
      getContainerVideo.each(function (){
        $(this).find('span').remove();
        $(this).removeClass('consent-marketing');
      });
      $('.uk-button-youtube[uk-lightbox]').parent().show();
    }
  }, false);

})(jQuery);
